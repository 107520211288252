<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
import Chart from './chart.vue';
export default {
  data () {
    return {
      cdata: [
        {
          // 名字需要与 “common/map/fujian.js” 地图数据文件里面定义的一一对应，不能是 “福州” 或者 “闽” 之类的缩写
          name: '四川省',
          value: 33,
          elseData:{
            // 这里放置地图 tooltip 里想显示的数据
          }
        },
        {
          name: '重庆市',
          value: 3,
        },
		{
		name: '广东省',
		value: 17,
		},
		{
		name: '北京市',
		value: 7,
		},
		{
		name: '上海市',
		value: 12,
		},
		{
		name: '浙江省',
		value: 9,
		},
		{
		name: '湖北省',
		value: 1,
		},
		{
		name: '河北省',
		value: 0,
		},
      ]
    }
  },
  components: {
    Chart,
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>