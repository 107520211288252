<template>
  <div id="centerRight1">
	<div class="title_bg">
	<p>实时上链监控</p>
	</div>
	<div class="bg-l3">
      <div class="d-flex jc-center body-box">
        <dv-scroll-board class="dv-scr-board" :config="config" ref="scrollBoard" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ['交易hash','应用名称','上链时间'],
		data: [],
        rowNum: 4, //表格行数
        headerHeight: 44,
        headerBGC: 'none', //表头
        oddRowBGC: 'rgba(6, 38, 92, 0.3)', //奇数行
        evenRowBGC: 'rgba(8, 88, 163, 0.4)', //偶数行
        index: false,
        align: ['center','center','center'],
		columnWidth:['270','126','92'],
		// waitTime:200000
      }
    }
  },
	created() {
		// 设置定时器，每隔15分钟（900000毫秒）执行一次 fetchData 方法
		this.updateData()
		this.timer = setInterval(this.updateData, 300000);
	},
	beforeDestroy() {
		// 清除定时器，防止内存泄漏和不必要的请求
		clearInterval(this.timer);
	},
	methods: {
		updateData() {
			// let that = this;
			fetch('https://data-screen.scszcb.com/v1/screen/data?chain=groupdac', {
			method: 'GET',
			headers: {
			'Content-Type': 'application/json'
			},
			})
			.then(response => response.json())
			.then(data => {
			// 处理返回的数据
			let rawData=data.data.currentTransferInfoResp2;
			// 循环处理数据（例如，将每个数据项转换为 HTML 字符串）
			let datas=rawData.map(item => [
			`<div class="hash">${item.hash}</div>`,
			`<div class="name">${item.appName}</div>`,
			`<span class="time">${item.transactionTime}</span>`
			]);
			this.$refs['scrollBoard'].updateRows(datas, 0)
				//this.$forceUpdate();
				// this.$nextTick(() => {
				// // 在这里执行DOM更新后的操作
				// });
				// that.$set(that.config.data,datas)
				// console.log(that.config,222)
				// that.$forceUpdate()
		})
		.catch(error => {
		// 处理错误
		console.error(error,22);
		});
		}
	}

}
</script>

<style lang="scss" scoped>
$box-height: 362px;
$box-width: 432px;
#centerRight1 {
  // padding: 16px;
  // padding-top: 20px;
  margin-top: 12px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
	overflow: hidden;
	.dv-scr-board {
	width: 100%;
	height: 318px;
	}
  }
	.title_bg{
		width: 400px;
		height: 40px;
		background-image: url("../assets/title_bg.png");
		background-size: cover;
		background-position: center center;
		line-height: 40px;
		margin-bottom: 12px;
		p{
			margin-left: 32px;
			font-size: 19px;
			background-image: linear-gradient(to bottom, #ffffff, #91dbfd);
			-webkit-background-clip: text;
			color: transparent;
			font-weight: bold;
		}
	}
	.bg-l3{
		height: 318px;
		width: $box-width;
		background-image: url("../assets/l3_bg.png");
		background-size: cover;
		background-position: center center;
	}
	.colorWhite{
		line-height: 32px;
		height: 32px;
		font-size: 14px;
	}
    
}
</style>
