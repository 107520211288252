<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-between bg_title">
			<!-- <img src="../assets/index_title.png"/> -->
			<div class="index_time">
				{{ dateYear }} {{ dateWeek }}
			</div>
			<div class="index_time">
				{{ dateDay }}
			</div>
        </div>	
<!-- 第1行数据 -->
        <div class="body-box" style="margin-top: -500px;">
          <div class="left-box">
			<right2 />
            <!-- <div class="d-flex flex-column zijin">
				<div class="d-flex flex-wrap flex-end"><p>首期资金</p><img src="../assets/s1.png"/><p style="font-size: 14px;">万元</p></div>
				<div class="d-flex flex-wrap flex-end"><p>可投资金</p><img src="../assets/s3.png"/><p style="font-size: 14px;">万元</p></div>
            </div> -->
            <div class="push-t-16">
				<left1 />
            </div>
			<div>
			<left2 />
			</div>
			
          </div>
		
		<!-- 第2行数据 -->
		<div class="content-box" style="margin-top: 20px;">
			<div>
				<center1 />
			</div>
			<div>
			<centerLeft2 />
			</div>
			
			<div>
			<center3 />
			</div>
		</div>
		
		<!-- 第3行数据 -->
		<div class="right-box">
			<div>
			<center />
			</div>
			<div >
			<right3 />
			</div>
			<div class="push-t-16">
			<left3 />
			</div>
		</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'
import centerLeft2 from './centerLeft2'
import left1 from './left1'
import left2 from './left2'
import left3 from './left3'
import right3 from './right3'
import center from './center'
import right2 from './right2'
import center3 from './center3'
import center1 from './center1'

export default {
  mixins: [ drawMixin ],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'],
      decorationColor: ['#568aea', '#000000'],
		hash:''
    }
  },
  components: {
	centerLeft2,
	left1,
	left2,
	left3,
	right3,
	center,
	right2,
	center3,
	center1
  },
  mounted() {
    this.timeFn()
    this.cancelLoading()
  },
  beforeDestroy () {
    clearInterval(this.timing)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/index.scss';
</style>
