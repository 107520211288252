<template>
  <div id="centerLeft1">
    <div class="bg-dt">
      <div class="d-flex jc-center">
        <CenterLeft2Chart />
      </div>
    </div>
  </div>
</template>

<script>
import CenterLeft2Chart from "@/components/echart/centerLeft/centerLeft2Chart";
export default {
  components: {
    CenterLeft2Chart
  },
};
</script>

<style lang="scss" scoped>
#centerLeft1 {
  $box-width: 811px;
  $box-height: 502px;
  height: $box-height;
  min-width: $box-width;
  margin-top: 60px;
  .bg-dt {
	height: 486px;
	background-image: url("../assets/maps.png");
	background-size: cover;
	width: 699px;
	margin: 0 auto;
	}
}
</style>