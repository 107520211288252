<template>
  <div id="centerRight1">
	<div class="title_bg">
	<p>渠道</p>
	</div>
	<div class="bg-qd">
      <div class="d-flex jc-center body-box">
        <dv-scroll-board class="dv-scr-board" :config="config" />
      </div>
		<div class="d-flex jc-center body-box">
		<dv-scroll-board class="dv-scr-board" :config="config2" />
		</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: [''],
        data: [
			["<div class='d-flex'><p class='shu1'></p><p class='bg11'><span class='cp_num'>1</span>Wegame</p></div>"],
			["<div class='d-flex'><p class='shu2'></p><p class='bg22'><span class='cp_num'>2</span>Steam</p></div>"],
			["<div class='d-flex'><p class='shu1'></p><p class='bg11'><span class='cp_num'>3</span>抖音</p></div>"],
			["<div class='d-flex'><p class='shu2'></p><p class='bg22'><span class='cp_num'>4</span>微信</p></div>"],
			["<div class='d-flex'><p class='shu1'></p><p class='bg11'><span class='cp_num'>5</span>TapTap</p></div>"],
			["<div class='d-flex'><p class='shu2'></p><p class='bg22'><span class='cp_num'>6</span>华为</p></div>"],
			["<div class='d-flex'><p class='shu1'></p><p class='bg11'><span class='cp_num'>7</span>联想</p></div>"],
			["<div class='d-flex'><p class='shu2'></p><p class='bg22'><span class='cp_num'>8</span>百度</p></div>"],
			["<div class='d-flex'><p class='shu1'></p><p class='bg11'><span class='cp_num'>9</span>小米</p></div>"],
			["<div class='d-flex'><p class='shu2'></p><p class='bg22'><span class='cp_num'>10</span>Epic</p></div>"],
			["<div class='d-flex'><p class='shu1'></p><p class='bg11'><span class='cp_num'>11</span>UC</p></div>"],
			["<div class='d-flex'><p class='shu2'></p><p class='bg22'><span class='cp_num'>12</span>360</p></div>"],
        ],
        rowNum: 5, //表格行数
        headerHeight: 1,
        headerBGC: 'none', //表头
        oddRowBGC: 'none', //奇数行
        evenRowBGC: 'none', //偶数行
        index: false,
        align: ['start'],
		waitTime:3000
      },
		config2: {
		header: [''],
		data: [
		["<div class='d-flex'><p class='shu1'></p><p class='bg11'><span class='cp_num'>13</span>好游快爆</p></div>"],
		["<div class='d-flex'><p class='shu2'></p><p class='bg22'><span class='cp_num'>14</span>奇游</p></div>"],
		["<div class='d-flex'><p class='shu1'></p><p class='bg11'><span class='cp_num'>15</span>Google</p></div>"],
		["<div class='d-flex'><p class='shu2'></p><p class='bg22'><span class='cp_num'>16</span>Apple</p></div>"],
		["<div class='d-flex'><p class='shu1'></p><p class='bg11'><span class='cp_num'>17</span>应用宝</p></div>"],
		["<div class='d-flex'><p class='shu2'></p><p class='bg22'><span class='cp_num'>18</span>3DM</p></div>"],
		["<div class='d-flex'><p class='shu1'></p><p class='bg11'><span class='cp_num'>19</span>游侠</p></div>"],
		["<div class='d-flex'><p class='shu2'></p><p class='bg22'><span class='cp_num'>20</span>机核网</p></div>"],
		["<div class='d-flex'><p class='shu1'></p><p class='bg11'><span class='cp_num'>21</span>游民星空</p></div>"],
		["<div class='d-flex'><p class='shu2'></p><p class='bg22'><span class='cp_num'>22</span>腾讯管家</p></div>"],
		["<div class='d-flex'><p class='shu1'></p><p class='bg11'><span class='cp_num'>23</span>小黑盒</p></div>"],
		["<div class='d-flex'><p class='shu2'></p><p class='bg22'><span class='cp_num'>24</span>杉果游戏</p></div>"],
		],
		rowNum: 5, //表格行数
		headerHeight: 1,
		headerBGC: 'none', //表头
		oddRowBGC: 'none', //奇数行
		evenRowBGC: 'none', //偶数行
		index: false,
		align: ['start'],
		waitTime:3000
		}
    }
  }
}
</script>

<style lang="scss" scoped>
$box-height: 230px;
$box-width: 456px;
#centerRight1 {
  // padding: 16px;
  // padding-top: 20px;
  height: $box-height;
  width: $box-width;
  border-radius: 5px;
  margin-top: 30px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
	overflow: hidden;
	margin-top: 6px;
	.dv-scr-board {
	width: 424px;
	height: 160px;
	margin-left: 26px;
	margin-top: 8px;
	}
  }
	.title_bg{
		width: 400px;
		height: 40px;
		background-image: url("../assets/title_bg.png");
		background-size: cover;
		background-position: center center;
		line-height: 40px;
		margin-bottom: 12px;
		p{
			margin-left: 32px;
			font-size: 19px;
			background-image: linear-gradient(to bottom, #ffffff, #91dbfd);
			-webkit-background-clip: text;
			color: transparent;
			font-weight: bold;
		}
	}
	.bg-qd{
		height: 172px;
		width: $box-width;
		background-image: url("../assets/qd_bg2.png");
		background-size: cover;
		background-position: center center;
		display: flex;
		.body-box{
			width: 210px;
			height: 160px;
		}
		.body-box:first-child{
			margin-right: 8px;
		}
	}
	.colorWhite{
		line-height: 32px;
		height: 32px;
		font-size: 14px;
	}
    
}
</style>
