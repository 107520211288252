<template>
  <div id="center">
	<div class="title_bg">
	<p>服务业务</p>
	</div>
    <div class="up">
		<div class="bg-ls item" v-for="(item,index) in titleItem" :key="index">
			<div :class="'divsj'+index">
			<p :class="'name'+index" >{{ item.title }}</p>
			</div>
		</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      titleItem: [
				{
				title: '研发',
				number: {
				number: [2860],
				textAlign: 'left',
				content: '{nt}',
				style: {
				fontSize: 26
				}
				}
				},
				{
				title: '发行',
				number: {
				number: [5900],
				textAlign: 'left',
				content: '{nt}',
				style: {
				fontSize: 26
				}
				}
				},
				{
				title: '供应链金融',
				number: {
				number: [1280],
				textAlign: 'left',
				content: '{nt}',
				style: {
				fontSize: 26
				}
				}
				},
				
				],
    
    }
  }
}
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
	.title_bg{
	width: 349px;
	height: 40px;
	background-image: url("../assets/c_title.png");
	background-size: cover;
	background-position: center center;
	line-height: 40px;
	margin-bottom: 16px;
	p{
	margin-left: 32px;
	font-size: 19px;
	background-image: linear-gradient(to bottom, #ffffff, #91dbfd);
	-webkit-background-clip: text;
	color: transparent;
	font-weight: bold;
	}
	}
  .up {
	padding: 0 50px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
	justify-content: space-between;
	height: 138px;
	background-image: url("../assets/fw.png");
	background-size: cover;
	background-position: center center;
	.divsj0{
		width: 218px;
		height: 89px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-image: url("../assets/fw_bg1.png"); 
		background-size: cover;
		background-position: center center;
		text-align: center;
	}
	.divsj1{
		width: 218px;
		height: 89px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-image: url("../assets/fw_bg2.png");
		background-size: cover;
		background-position: center center;
		text-align: center;
	}
	.divsj2{
		width: 283px;
		height: 86px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-image: url("../assets/fw_bg3.png");
		background-size: cover;
		background-position: center center;
		text-align: center;
	}
	.item {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	.name0,.name1{
		font-size: 20px;
		background-image: linear-gradient(to bottom, #ffffff, #91dbfd);
		-webkit-background-clip: text;
		color: transparent;
		font-weight: bold;
		line-height: 24px;
		margin-top: -15px;
		z-index: 99;
		margin-left: 76px;
	}
	.name2{
		font-size: 20px;
		background-image: linear-gradient(to bottom, #ffffff, #91dbfd);
		-webkit-background-clip: text;
		color: transparent;
		font-weight: bold;
		line-height: 24px;
		margin-top: -15px;
		z-index: 99;
		margin-left: 96px;
	}
	}

  }

}
</style>
