<template>
  <div>
    <Echart
      id="centreLeft2Chart"
      ref="centreLeft2ChartRef"
      :options="options"
      height="486px"
      width="776px"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart';
export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        // 设置点的位置(经纬度)
        const geoCoordMap = {
			四川省: [104.065735,30.659462],
			重庆市: [106.504962, 29.533155],
			广东省: [113.429919, 23.334643],
			北京市: [116.405285, 39.904989],
			上海市: [121.438737, 32.072559],
			浙江省: [120.153576, 30.287459],
			湖北省: [114.298572, 30.584355],
        };
        let seriesData = [
          {
            name: '四川省',
          },
			{
			name: '重庆市',
			},
			{
			name: '广东省',
			},
			{
			name: '北京市',
			},
			{
			name: '上海市',
			},
			{
			name: '浙江省',
			},
			{
			name: '湖北省',
			},
        ];
        let convertData = function (data) {
          let scatterData = [];
          for (var i = 0; i < data.length; i++) {
            var geoCoord = geoCoordMap[data[i].name];
            if (geoCoord) {
              scatterData.push({
                name: data[i].name,
                value: geoCoord.concat(data[i].value),
              });
            }
          }
          return scatterData;
        };
        this.options = {
          showLegendSymbol: true,
          // tooltip: {
          //   trigger: 'item',
          //   textStyle: {
          //     fontSize: 22,
          //     lineHeight: 22,
          //   },
          //   position: point => {
          //     // 固定在顶部
          //     return [point[0] + 50, point[1] - 20];
          //   },
			//formatter: function (params) {
			// 自定义格式化提示信息
			// if(params.value>0){
			// 	return '服务企业' + ': ' + params.value+'家';
			// 	// return'<div class="fuwu" style="display: flex;flex-direction: column;align-items: center;text-align: center;background-image: url("../../../../assets/sj.png");background-size: cover;background-position: center center;width: 118px;height: 84px;z-index:999"><div class="fw_name">服务企业数</div><div class="fw_num">' + params.value+'</div></div>'
			// }else{
			// 	return params.name
			// }
			// }
            // 如果需要自定义 tooltip样式，需要使用formatter
            
			// formatter: params => {
			// 	if(params.value>0){
			// 	return '服务企业' + ': ' + params.value+'家';
			// 	// return'<div class="fuwu" style="display: flex;flex-direction: column;align-items: center;text-align: center;background-image: url("../../../../assets/sj.png");background-size: cover;background-position: center center;width: 118px;height: 84px;z-index:999"><div class="fw_name">服务企业数</div><div class="fw_num">' + params.value+'</div></div>'
			// 	}else{
			// 	return params.name
			// 	}
			// }
           
          //},
          // visualMap: {
          //   left: 20,
          //   bottom: 20,
          //   pieces: [
          //     { gte: 1000, label: "1000个以上" }, // 不指定 max，表示 max 为无限大（Infinity）。
          //     { gte: 600, lte: 999, label: "600-999个" },
          //     { gte: 200, lte: 599, label: "200-599个" },
          //     { gte: 50, lte: 199, label: "49-199个" },
          //     { gte: 10, lte: 49, label: "10-49个" },
          //     { lte: 9, label: "0-9个" }, // 不指定 min，表示 min 为无限大（-Infinity）。
          //   ],
          //   inRange: {
          //     // 渐变颜色，从小到大
          //     color: [
          //       "#c3d7df",
          //       "#5cb3cc",
          //       "#8abcd1",
          //       "#66a9c9",
          //       "#2f90b9",
          //       "#1f90b9",
          //     ],
          //   },
          //   textStyle: {
          //     color: "#fff",
          //   },
          // },
			tooltip: {
			enterable: true,
			trigger: 'item',
			borderColor:'transparent',
			backgroundColor:'none',
			textStyle: {
			color: '#8de0ff',
			fontSize: 12
			},
			triggerOn: 'mousemove|click',
			position: function(point) {
			// let position = point
			// position[1] = position[1] + 5
			// position[0] = position[0] - 90
			// if (position[0] < 0) {
			// position[0] = 10
			// }
			// if (position[1] > 340) {
			// position[1] = 340
			// }
			// return point
			return [point[0] + 50, point[1] - 20];
			},
			formatter(params) {
				console.log(params,2222)
				if(params.value>0){
				let htmlStr = `
				<div style='display: flex;flex-direction: column;text-align: center;background-image: url('../../../../assets/sj.png');z-index:9999;opacity: 1;text-align: center;'>
				<div style='display:flex;align-items: center;text-align: center;justify-content: center;border:1px solid #37dcff;background-color:rgb(5 54 100 / 75%);width: 120px;height: 60px;flex-direction: column;'>
				<div style='width: 100%;color:#8de0ff;opacity: 1;text-align: center;margin-top:10px'>服务企业数</div>
				<div style='text-align:center;margin-top:5px;color:#ffffff;opacity: 1;'>
				${params.value}
				</div>
				</div>
				</div>
				`
				return htmlStr
				}else{
				let htmlStr = `
				<div style='display: flex;flex-direction: column;text-align: center;background-image: url('../../../../assets/sj.png');z-index:9999;opacity: 1;text-align: center;'>
				<div style='display:flex;align-items: center;text-align: center;justify-content: center;border:1px solid #37dcff;background-color:rgb(5 54 100 / 75%);width: 120px;height: 60px;flex-direction: column;'>
				<div style='width: 100%;color:#8de0ff;opacity: 1;text-align: center'>${params.name}</div>
				
				</div>
				</div>
				`
				return htmlStr
				}
			
			}
			},
emphasis: {
label: {
show: false
}
},
          // 底部背景
          geo: {
            show: true,
            aspectScale: 0.85, //长宽比
            zoom: 1.2,
            top: '10%',
            left: '16%',
			map: 'china',
            roam: true,
            itemStyle: {
              normal: {
               areaColor: 'rgba(7,114,204,0)',
               shadowColor: 'rgb(169,225,252,0)',
                shadowOffsetX: 5,
                shadowOffsetY: 5,
				borderColor: 'rgb(169,225,252,1)', // 设置边框颜色
				borderWidth: 1, // 设置边框宽度
				fillColor: '#7ebfe9' // 设置填充颜色
              },
              emphasis: {
                areaColor: '#fe0000',
              },
            },
			
          },
          series: [
            {
              name: '服务企业数',
              type: 'map',
              aspectScale: 0.85, //长宽比
              zoom: 1.2,
              mapType: 'china', // 自定义扩展图表类型
              top: '10%',
              left: '16%',
              itemStyle: {
                normal: {
                  color: 'red',
                  areaColor: 'rgba(19,54,162, 0)',
                  borderColor: 'rgba(255,255,255,.3)',
                  borderWidth: 2,
                  shadowBlur: 7,
                  shadowColor: '#00f2fc',
                },
                emphasis: {
                  areaColor: 'rgba(146,226,255,.3)',
                  borderColor: '#f8cd38',
                  borderWidth: 2,
                  shadowBlur: 10,
                  shadowColor: '#00f2fc',
                },
              },
              label: {
                formatter: params => `${params.name}`,
                show: false,
                position: 'insideRight',
                textStyle: {
                  fontSize: 14,
                  color: '#efefef',
                },
                emphasis: {
                  textStyle: {
                    color: '#ffffff',
                  },
                },
              },
              data: newData,
            },
            {
              type: 'effectScatter',
              coordinateSystem: 'geo',
              symbolSize: 8,
              effectType: 'ripple',
              legendHoverLink: false,
              showEffectOn: 'render',
              rippleEffect: {
                period: 5,
                scale: 10,
                brushType: 'stroke',
              },
              zlevel: 1,
              itemStyle: {
                normal: {
                  color: '#ffffff',
                  shadowBlur: 20,
                  shadowColor: '#fff',
                },
              },
              data: convertData(seriesData),
            },
          ],
        };
        // 重新选择区域
        this.handleMapRandomSelect();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 开启定时器
    startInterval() {
      const _self = this;
      // 应通过接口获取配置时间，暂时写死5s
      const time = 2000;
      if (this.intervalId !== null) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(() => {
        _self.reSelectMapRandomArea();
      }, time);
    },
    // 重新随机选中地图区域
    reSelectMapRandomArea() {
      const length = 9;
      this.$nextTick(() => {
        try {
          const map = this.$refs.centreLeft2ChartRef.chart;
          let index = Math.floor(Math.random() * length);
          while (index === this.preSelectMapIndex || index >= length) {
            index = Math.floor(Math.random() * length);
          }
          map.dispatchAction({
            type: 'mapUnSelect',
            seriesIndex: 0,
            dataIndex: this.preSelectMapIndex,
          });
          map.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: index,
          });
          map.dispatchAction({
            type: 'mapSelect',
            seriesIndex: 0,
            dataIndex: index,
          });
          this.preSelectMapIndex = index;
        } catch (error) {
          console.log(error)
        }
      });
    },
    handleMapRandomSelect() {
      this.$nextTick(() => {
        try {
          const map = this.$refs.centreLeft2ChartRef.chart;
          const _self = this;
          setTimeout(() => {
            _self.reSelectMapRandomArea();
          }, 0);
          // 移入区域，清除定时器、取消之前选中并选中当前
          map.on('mouseover', function (params) {
            clearInterval(_self.intervalId);
            map.dispatchAction({
              type: 'mapUnSelect',
              seriesIndex: 0,
              dataIndex: _self.preSelectMapIndex,
            });
            map.dispatchAction({
              type: 'mapSelect',
              seriesIndex: 0,
              dataIndex: params.dataIndex,
            });
            _self.preSelectMapIndex = params.dataIndex;
          });
          // 移出区域重新随机选中地图区域，并开启定时器
          map.on('globalout', function () {
            _self.reSelectMapRandomArea();
            _self.startInterval();
          });
          this.startInterval();
        } catch (error) {
          console.log(error)
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
#centreLeft2Chart{
left: -20px;
top: -4px;
}

.fuwu{
	width: 118px;
	height: 84px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	background-image: url('../../../../assets/sj.png');
}
</style>
