<template>
  <div id="centerRight1">
	<div class="title_bg">
	<p>区块链节点</p>
	</div>
	<div class="bg-r3">
      <div class="d-flex jc-center body-box">
        <dv-scroll-board class="dv-scr-board" :config="config" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      config: {
        header: ['节点名称','建设单位','状态'],
        data: [
			['<div class="hash">游戏创新发展中心节点</div>','<div class="name">四川游戏创新发展中心</div>','<span style="color:#96d4ff;">正常</span>'],
			['<div class="hash">国家版权创新发展基地<br/>区块链版权中心节点</div>','<div class="name">国家版权创新发展基地（天府新区）</div>','<span style="color:#96d4ff;">正常</span>'],
			['<div class="hash">数字世界节点</div>','<div class="name">四川数字世界文化科技有限公司</div>','<span style="color:#96d4ff;">正常</span>'],
			['<div class="hash">数字出版节点</div>','<div class="name">四川数字出版传媒有限公司</div>','<span style="color:#96d4ff;">正常</span>'],
			['<div class="hash">星火·链网（成都）骨干节点</div>','<div class="name">四川省区块链行业协会</div>','<span style="color:#96d4ff;">正常</span>'],
        ],
        rowNum: 5, //表格行数
        headerHeight: 36,
        headerBGC: 'none', //表头
        oddRowBGC: 'rgba(6, 38, 92, 0.3)', //奇数行
        evenRowBGC: 'rgba(8, 88, 163, 0.4)', //偶数行
        index: false,
        align: ['center','center','center'],
		columnWidth:['208','166','90'],
		// waitTime:200000
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$box-height: 292px;
$box-width: 432px;
#centerRight1 {
  // padding: 16px;
  // padding-top: 20px;
  height: $box-height;
  width: $box-width;
  margin-top: 16px;
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  .text {
    color: #c3cbde;
  }
  .body-box {
	overflow: hidden;
	.dv-scr-board {
	width: 100%;
	height: 238px;
	}
  }
	.title_bg{
		width: 400px;
		height: 40px;
		background-image: url("../assets/title_bg.png");
		background-size: cover;
		background-position: center center;
		line-height: 40px;
		margin-bottom: 16px;
		p{
			margin-left: 32px;
			font-size: 19px;
			background-image: linear-gradient(to bottom, #ffffff, #91dbfd);
			-webkit-background-clip: text;
			color: transparent;
			font-weight: bold;
		}
	}
	.bg-r3{
		height: 238px;
		width: $box-width;
		background-image: url("../assets/qkjd.png");
		background-size: cover;
		background-position: center center;
	}
	.colorWhite{
		line-height: 32px;
		height: 32px;
		font-size: 14px;
	}
    
}
</style>
